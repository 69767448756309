import { currency_options } from '@ninja/consts';

export default function getFormattedValue(value, currency = null) {
  let currency_config = currency ? currency_options[currency] : window.currency_config;

  if (!currency_config) return value;

  const numericValue = Number(value);
  if (isNaN(numericValue)) return `0${currency_config.symbol}`;

  // check if the number has a decimal part
  const hasDecimal = numericValue % 1 !== 0;
  const decimalPlaces = hasDecimal ? 2 : 0;

  if (currency_config.sign_location === 'pre') {
    return numericValue < 0
      ? `-${Math.abs(numericValue).toFixed(decimalPlaces)}${currency_config.symbol}`
      : `${Math.abs(numericValue).toFixed(decimalPlaces)}${currency_config.symbol}`;
  } else {
    return `${numericValue.toFixed(decimalPlaces)}${currency_config.symbol}`;
  }
}