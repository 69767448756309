import NinjaTable from 'components/tables/NinjaTable';
import React from 'react';
import DisconnectButton from 'modules/apps/components/DisconnectButton';

const columns = [
  {
    key: 'id',
    frozen: true,
    name: 'ID',
    resizable: true,
  },
  {
    key: 'name',
    frozen: true,
    name: 'Name',
    resizable: true,
  },
  {
    key: 'currency',
    frozen: true,
    name: 'Currency',
    resizable: true,
  },
  {
    key: 'base_url',
    frozen: true,
    name: 'Url',
    resizable: true,
  },
  {
    key: 'disconnect',
    name: 'Diconnect',
    width: 130,
    renderCell: ({ row }) => {
      return <DisconnectButton integration='shopify' id={row.id} name={row.name} />;
    },
  },
];

const ConnectedShopifyStores = (props) => {
  return (
    <NinjaTable
      title='Connected stores'
      columns={columns}
      dataProvider='listShopify'
      footerProps={{ showPerPage: false }}
      disableFooter
    />
  );
};

export default ConnectedShopifyStores;
