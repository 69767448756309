import React from 'react';
import { mdiCircle } from '@mdi/js';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const statuses = {
  active: { color: Ninja.colors.success },
  paused: { color: Ninja.colors.error },
  archived: { color: Ninja.colors.gray },
  removed: { color: Ninja.colors.error },
  deleted: { color: Ninja.colors.error },
};

const StatusFormatter = ({ row: { status }, title = true }) => {

  if (!status) {
    return null
  }

  return (
    <TableIcons
      triggerHoverOnCell
      icons={[
        {
          icon: mdiCircle,
          color: statuses[status.toLowerCase()].color,
          tooltip: title && status?.toLowerCase(),
          size: '0.6rem',
        },
      ]}
    />
  );
};

export default StatusFormatter;
