import { SELECT_COLUMN_KEY } from 'react-data-grid';
import SelectHeaderCell from './SelectHeaderCell';
import SelectFormatter from './SelectFormatter';
import SelectGroupFormatter from './SelectGroupFormatter';

export const SelectColumn = {
  key: SELECT_COLUMN_KEY,
  name: '',
  cellClass: 'nnj-selector-cell',
  headerCellClass: 'nnj-selector-header',
  width: 55,
  maxWidth: 100,
  resizable: false,
  frozen: true,
  renderHeaderCell: SelectHeaderCell,
  renderCell: SelectFormatter,
  groupFormatter: SelectGroupFormatter,
};
