import React from 'react';
import NameDefinition from './NameDefinition';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { mdiFinance } from '@mdi/js';

const RoasDefinition = ({
  showIcon,
  textFormat = 'text-xs font-semibold w-fit text-background-dark',
}) => {
  return (
    <div className='flex items-center gap-2'>
      {showIcon && <Icon path={mdiFinance} size={0.8} className='text-orange' />}
      <NameDefinition
        name='ROAS'
        definiton='Return on Ad Spent: Gross Income / Ad Spent'
        textSize={textFormat}
      />
    </div>
  );
};

export default RoasDefinition;

RoasDefinition.propTypes = {
  showIcon: PropTypes.object,
  textFormat: PropTypes.string,
};
