import { Tabs } from 'antd';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import React from 'react';
import PropTypes from 'prop-types';
import useQueryParams from 'hooks/useQueryParams';
import NetworkAccountTrackingManualSettingsForm from 'modules/network-accounts/forms/NetworkAccountTrackingManualSettingsForm';
import NetworkAccountTrackingAutomaticSetupForm from 'modules/network-accounts/forms/NetworkAccountTrackingAutomaticSetupForm';

const NetworkAccountTrackingSettingsDrawer = (props) => {
  const { searchParams, ...rest } = props;
  const { account_id } = searchParams;

  return (
    <AutomaticDrawer
      bodyStyle={{ padding: 0 }}
      title='Account Tracking Settings'
      size='large'
      {...rest}
    >
      <div className='px-4'>
        <Tabs
          defaultActiveKey='2'
          items={[
            {
              key: 'automatic',
              label: 'Automatic Set Up',
              children: (
                <div className='py-4'>
                  <NetworkAccountTrackingAutomaticSetupForm accountID={account_id} />
                </div>
              ),
            },
            {
              key: 'manual',
              label: 'Manual Set Up',
              children: (
                <div className='py-4'>
                  <NetworkAccountTrackingManualSettingsForm accountID={account_id} />
                </div>
              ),
            },
          ]}
        />
      </div>
    </AutomaticDrawer>
  );
};

NetworkAccountTrackingSettingsDrawer.requiredParams = {
  account_id: (p) => parseInt(p) > 0,
  setting: (p) => p === 'tracking',
};

NetworkAccountTrackingSettingsDrawer.useDrawer = (accountID) => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ account_id: accountID, setting: 'tracking' });
  const close = () => removeParams(['account_id', 'setting']);

  return {
    open,
    close,
  };
};

NetworkAccountTrackingSettingsDrawer.propTypes = {
  searchParams: PropTypes.object.isRequired,
};

export default NetworkAccountTrackingSettingsDrawer;
