import {
  mdiLightbulb,
  mdiViewDashboard,
  mdiChartLineVariant,
  mdiAccountMultiple,
  mdiFileChartOutline,
  mdiCameraIris,
  mdiCurrencyUsdCircleOutline,
  mdiAccountDetailsOutline,
  mdiBullhornOutline,
  mdiCashUsdOutline,
  mdiAccountReactivateOutline,
  mdiSwapHorizontal,
  mdiAccountSearch,
  mdiViewListOutline,
  mdiPlus,
} from '@mdi/js';
import Icon from '@mdi/react';
import React, { useMemo, useState, useEffect } from 'react';
import P from 'router/paths';
import { useSidebar } from 'store/redux/states/ui/ui.hooks';
import { ConfigProvider, Menu } from 'antd';
import Ninja from '@ninja';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import useCrmForms from 'modules/CRMModule/hooks/useCrmForms';
import useEmailConfirm from 'modules/setup/hooks/useEmailConfirm';
import { useListShopifyQuery } from 'modules/apps/apps/ShopifyApp/api/shopify.api';
import { useListWoocommerceQuery } from 'modules/apps/apps/WoocommerceApp/api/woocommerce.api';
import useNetworkAccounts from 'store/redux/hooks/useNetworkAccounts';
import useWebsites from 'modules/website/hooks/useWebsites';

const SidebarMenu = () => {
  const [sidebarOpen] = useSidebar();
  const { workspace } = useWorkspace();
  const { websites } = useWebsites();
  const { isConfirmed } = useEmailConfirm();
  const { accounts } = useNetworkAccounts({});
  const { data: shopify = [] } = useListShopifyQuery();
  const { data: wooc = [] } = useListWoocommerceQuery();

  const isSetupDone =
    websites.length &&
    workspace?.setup_finished &&
    accounts.length &&
    isConfirmed &&
    (shopify.data?.length || wooc.length);

  const { forms } = useCrmForms();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const items = useMemo(() => {
    const items = [
      !isSetupDone && {
        key: P.SETUP.INDEX,
        label: <Link to={P.SETUP.INDEX}>Getting Started</Link>,
        icon: <Icon path={mdiLightbulb} />,
        className: 'getting-started-item',
      },
      {
        type: 'group',
        key: 'statistics',
        label: 'Statistics',
        children: [
          {
            key: P.DASHBOARD.INDEX,
            label: <Link to={P.DASHBOARD.INDEX}>Dashboard</Link>,
            icon: <Icon path={mdiViewDashboard} />,
          },
          {
            key: P.SALES.INDEX,
            label: <Link to={P.SALES.CRM}>Acquisition</Link>,
            icon: <Icon path={mdiChartLineVariant} />,
          },
          {
            key: P.POSTBACKS.INDEX,
            label: <Link to={P.POSTBACKS.INDEX}>Retention</Link>,
            icon: <Icon path={mdiAccountMultiple} />,
          },
          {
            key: P.REPORTING.INDEX,
            label: <Link to={P.REPORTING.INDEX}>Reporting</Link>,
            icon: <Icon path={mdiFileChartOutline} />,
          },
          {
            key: P.REPORTING.CREATIVE,
            label: <Link to={P.REPORTING.CREATIVE}>Creative Analysis</Link>,
            icon: <Icon path={mdiCameraIris} />,
          },
        ],
      },
      {
        type: 'group',
        key: 'crm',
        label: 'CRM',
        children: [
          {
            key: P.CRM.CONTACTS,
            label: <Link to={P.CRM.CONTACTS}>Contacts</Link>,
            icon: <Icon path={mdiViewListOutline} />,
          },
          {
            key: P.CRM.DEALS,
            label: <Link to={P.CRM.DEALS}>Deals</Link>,
            icon: <Icon path={mdiCurrencyUsdCircleOutline} />,
          },
          {
            key: 'leads',
            label: 'Leads',
            icon: <Icon path={mdiAccountDetailsOutline} />,
            children: forms?.length
              ? forms.map((form) => {
                return {
                  label: (
                    <Link style={{ color: 'unset' }} to={P.CRM.DATA_LIST.replace(':id', form.id)}>
                      {form.name} - {form.leads_count} Leads
                    </Link>
                  ),
                  icon: <Icon path={mdiAccountSearch} />,
                  key: form.id + ':list',
                };
              })
              : [
                {
                  label: (
                    <Link style={{ color: 'unset' }} to={P.CRM.INDEX}>
                      Create New Form
                    </Link>
                  ),
                  key: 'create_form',
                  icon: <Icon path={mdiPlus} />,
                },
              ],
          },
        ],
      },
      {
        type: 'group',
        key: 'setup',
        label: 'Setup',
        children: [
          {
            key: P.SETUP.MARKETING,
            label: <Link to={P.SETUP.MARKETING}>Marketing Channels</Link>,
            icon: <Icon path={mdiBullhornOutline} />,
          },
          {
            key: P.SETUP.REVENUE,
            label: <Link to={P.SETUP.REVENUE}>Revenue Sources</Link>,
            icon: <Icon path={mdiCashUsdOutline} />,
          },
          {
            key: P.WEBSITES.INDEX,
            label: <Link to={P.WEBSITES.INDEX}>Tracking</Link>,
            icon: <Icon path={mdiAccountReactivateOutline} />,
          },
        ],
      },
    ];
    return items;
  }, [workspace, forms, isSetupDone]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            darkItemBg: Ninja.colors.bgColorDark,
            darkItemHoverBg: Ninja.colors.orange,
            darkItemColor: 'white',
            iconSize: '20px',
            darkPopupBg: '#151a22',
          },
        },
      }}
    >
      <Menu
        className='w-full'
        inlineCollapsed={!sidebarOpen}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode={isMobile ? 'inline' : 'vertical'}
        theme='dark'
        selectedKeys={[!isSetupDone ? P.SETUP.INDEX : null, window.location.pathname]}
        items={items}
      />
    </ConfigProvider>
  );
};

export default SidebarMenu;
