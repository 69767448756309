import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import { Skeleton, Tabs } from 'antd';
import React, { useMemo } from 'react';
import { useGetNetworkAccountQuery } from 'store/redux/apis/network-accounts/network-accounts.api';
import useQueryParams from 'hooks/useQueryParams';
import BadgedTabHeader from 'components/Tab/BadgetTabHeader';
import AccountInfoConversionsTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoConversionsTab';
import AccountInfoInfoTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoInfoTab';
import AccountInfoCampaignsTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoCampaignsTab';
import AccountInfoAdsetsTab from 'modules/network-accounts/drawers/NetworkAccountInfoDrawer/Tabs/AccountInfoAdsetsTab';

const defaultTab = 'info';

const NetworkAccountInfoDrawer = (props) => {
  const { searchParams, ...rest } = props;
  const { account_info_id, tab = defaultTab } = searchParams;
  const { data: account = {}, isFetching } = useGetNetworkAccountQuery({
    id: parseInt(account_info_id),
  });
  const { setUrlParams, removeParams } = useQueryParams();

  const tabs = useMemo(
    () => [
      {
        label: 'Account Info',
        key: 'info',
        children: <AccountInfoInfoTab accountId={account.id} />,
      },
      {
        label: <BadgedTabHeader label='Campaigns' count={account.campaigns_count} />,
        key: 'campaigns',
        children: <AccountInfoCampaignsTab accountId={account.id} />,
      },
      {
        label: <BadgedTabHeader label='Adsets' count={account.adsets_count} />,
        key: 'adsets',
        children: <AccountInfoAdsetsTab accountId={account.id} />,
      },
      {
        label: <BadgedTabHeader label='Conversions' count={parseInt(account.conversions_count)} />,
        key: 'conversions',
        children: <AccountInfoConversionsTab accountId={account.id} />,
      },
    ],
    [account]
  );

  return (
    <AutomaticDrawer
      onClose={() => removeParams(['tab'])}
      size='large'
      bodyStyle={{ padding: 0 }}
      title={account?.name ? `Account: ${account?.name} Details` : 'Account Info'}
      {...rest}
    >
      <Skeleton loading={isFetching} active>
        <div className='px-4'>
          <Tabs
            defaultActiveKey={tab}
            activeKey={tab}
            onChange={(tab) => {
              setUrlParams({ tab: tab });
            }}
            items={tabs}
          />
        </div>
      </Skeleton>
    </AutomaticDrawer>
  );
};

NetworkAccountInfoDrawer.requiredParams = {
  account_info_id: (p) => parseInt(p) > 0,
};

export default NetworkAccountInfoDrawer;
